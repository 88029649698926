import React, {FormEvent, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {RouteComponentProps, withRouter} from "react-router-dom";
import styled from "styled-components";
import {get, isEmpty, omit, isNil} from 'lodash';
import {AxiosResponse} from "axios";
import {useHistory} from "react-router-dom";

import {repository} from "shared/repositories";
import {getButton, getContent, getGeneralBody, getGeneralPage, getParentRow} from "components/styledComponents";
import {CodeField, EmailField, FormCheck, FormFields, PhoneField, Products} from "../Campaigns/style-step/formFields";
import {ModalFormWizard} from "components/modals/modal-form";
import {ICampaign, IPage, IProduct} from "../Campaigns/models";
import {Action, IContent, ISubBlock, IValParams, PageTemplateTypes, TypeContent} from "../Campaigns/style-step/models";
import {Presenter} from './presenter';
import {Checkbox} from "../../components/Field";
import {INotificationStore, notificationEnhancer} from "../../store";
import {getAnyQueryParams, getErrorMessage} from "utils/utils";

export const Landing = notificationEnhancer((props: INotificationStore & RouteComponentProps<{ id: string, slug?: string }>) => {
    const [pages, setPages] = useState<IPage[]>([]);
    const history = useHistory();
    const [products, setProducts] = useState<IProduct[]>([]);
    // popup confirm
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState<string | null>(null);
    const [dataAll, setDataAll] = useState<any>({});
    // render need page
    const [step, setStep] = useState<PageTemplateTypes>(PageTemplateTypes.welcome);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        validateSlug();
        repository.campaign.getCampaignPages(Number(props.match.params.id)).then((response) => {
            const formPage = response.find(page => page.template === PageTemplateTypes.form);
            const pageWithProducts = response.find(page => page?.products && page.products.length > 0) || { products: [] };

            setPages(response);

            if (formPage?.products) {
                setProducts(formPage.products);
            } else {
                setProducts(pageWithProducts.products);
            }

        })
    }, []);

    const validateSlug = async () => {
        if (isNil(props.match.params.slug)) {
            return;
        }

        const response = await repository.campaign.getCampaignItem(Number(props.match.params.id));
        const campaign: ICampaign = response.data.data;

        if (isNil(campaign.slug) || campaign.slug !== props.match.params.slug) {
            history.push(`/${props.match.params.id}`)
        }


    }

    const handleSubmitConfirm = (e: FormEvent) => {
        const formData = new FormData(e.target as any);
        const formProps = Object.fromEntries(formData);

        if (confirm) {
            if (isEmpty(formProps.codeError)) {
                if (confirm === 'phone') {
                    const validPhone = `${dataAll.phone}`.replace(/[+()\- ]/g, '')
                    updatePhone(validPhone, `${formProps.code}`).then((response) => {
                        if (response?.data) {
                            setStep(PageTemplateTypes.form);
                            setConfirm(null);
                            setOpen(false);
                            setErrorMessage('');
                        } else {
                            setErrorMessage('Code is not valid');
                            props.notificationStore.error('Code is not valid');
                        }
                    })
                } else {
                    updateEmail(`${dataAll.email}`, `${formProps.code}`).then((response) => {
                        if (response?.data) {
                            setStep(PageTemplateTypes.form);
                            setConfirm(null);
                            setOpen(false);
                            setErrorMessage('');
                        } else {
                            setErrorMessage('Code is not valid');
                            props.notificationStore.error('Code is not valid');
                        }
                    })
                }

            }
        } else {
            if (isEmpty(formProps.emailError) &&  isEmpty(formProps.phoneError)) {
                if (formProps.phone) {
                    const phoneNumber = `${formProps.phone}`.replace(/[+()\- ]/g, '')
                    sendPhone(phoneNumber).then((response) => {
                        if ([200, 201].includes(response.status)) {
                            setConfirm('phone');
                            props.notificationStore.success('Please check your phone');
                            setDataAll({ ...dataAll, ...formProps });
                        } else {
                            props.notificationStore.error(getErrorMessage(response));
                        }
                    })
                }
                else if (formProps.email) {
                    sendEmail(`${formProps.email}`).then((response) => {
                        if ([200, 201].includes(response.status)) {
                            setConfirm('email');
                            props.notificationStore.success('Please check your email');
                            setDataAll({...dataAll, ...formProps})
                        } else {
                            props.notificationStore.error(getErrorMessage(response));
                        }
                    })
                }
            }
        }
    }

    const sendEmail = (email: string): Promise<AxiosResponse<{ data: any }>> => repository.campaign.createConfirmEmail(email);

    const updateEmail = (email: string, code: string): Promise<AxiosResponse<{ data: boolean }>> => repository.campaign.updateConfirmEmail(email, code);

    const sendPhone = (phone: string): Promise<AxiosResponse<{ data: any }>> => repository.campaign.createConfirmPhone(phone);

    const updatePhone = (phone: string, code: string): Promise<AxiosResponse<{ data: boolean }>> => repository.campaign.updateConfirmPhone(phone, code);

    const closePopup = () => {
        setOpen(false);
        setConfirm(null);
    }

    const handleSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        const formData = new FormData(e.target as any);
        const formProps = Object.fromEntries(formData);
        const formValid = isEmpty(formProps.firstNameError) &&
            isEmpty(formProps.ibanError) &&
            isEmpty(formProps.bicError) &&
            formProps.product

        if (formValid) {
            const ibanData  = new FormData();
            ibanData.append('iban', formProps.payment_method);
            try {
                const validateIban = await repository.validate.validateIban(ibanData);

                if (![200, 201].includes(validateIban.status)) {
                    props.notificationStore.error('IBAN not valid')
                    return;
                }

                const bicData  = new FormData();
                bicData.append('bic', formProps.bic);
                const validateBic = await repository.validate.validateBic(bicData);
                if (![200, 201].includes(validateBic.status)) {
                    props.notificationStore.error('BIC not valid')
                    return;
                }

                setStep(PageTemplateTypes.upload);
                const newData = { ...dataAll, ...formProps };
                setDataAll(newData);
            } catch (_) {
                props.notificationStore.error('IBAN not valid');
            }



        } else {
            props.notificationStore.error('form not valid or not choice product');
        }
    }

    const handleSubmitUpload = (e: FormEvent) => {
        e.preventDefault();
        const { search } = props.location;
        const { track_code } = getAnyQueryParams(search);

        const formData = new FormData(e.target as any);
        const formProps = Object.fromEntries(formData);

        const name = get(formProps.check, 'name');

        if (name) {
            setDataAll({ ...dataAll, ...formProps })
            const data = new FormData();
            const { product, name, lastName, payment_method, bic } = dataAll;

            data.append('campaign_id', props.match.params.id);
            data.append('check', formProps.check);
            data.append('product_id', product);
            data.append('name', `${name} ${lastName}`);
            data.append('cashback_number', uuidv4());
            data.append('payment_method', payment_method);
            data.append('bic', bic);

            if (dataAll.phone) {
                data.append('phone', dataAll.phone);
            }

            if (dataAll.email) {
                data.append('email', dataAll.email);
            }

            const flags = omit(
                dataAll,
                [
                    'product',
                    'bic',
                    'bicError',
                    'email',
                    'emailError',
                    'phone',
                    'phoneError',
                    'name',
                    'firstNameError',
                    'lastName',
                    'lastNameError',
                    'payment_method',
                    'ibanError'
                ]);

            Object.keys(flags).forEach(flag => {
                data.append(`checkbox[${flag}]`, 'true');
            })

            repository.campaign.createCheck(data, track_code)
                .then((response) => {
                    if (response.status === 201) {
                        setStep(PageTemplateTypes.thank_you);
                        setDataAll({});
                    } else {
                        props.notificationStore.error(getErrorMessage(response))
                    }
                })
                .catch((response) => {
                    props.notificationStore.error(getErrorMessage(response))
                });
        } else {
            props.notificationStore.error("you need to upload an image of the check");
        }
    }

    const handleButtonClick = (param?: IValParams) => {
        if (!param || !param.action) {
            return;
        }

        if (param.action === Action.moveToPage) {
            if (param.page === PageTemplateTypes.popup_confirm) {
                setOpen(true);
            }
            else if (param.page) {
                setStep(param.page);
            }
        }
    }

    const renderBlock = (block: ISubBlock) => {
        const key = uuidv4();
        const { content, rowParams } = block;

        const Parent = getParentRow(rowParams);

        return (
            <Parent key={key}>
                {content.map(renderContent)}
            </Parent>
        )
    }

    const renderContent = (content: IContent) => {
        const keyContent = uuidv4();
        const { value, colParams, type, valueParams } = content;

        const Content = getContent(colParams);

        const StyleButton = getButton(valueParams);

        switch (type) {
            case TypeContent.text: {
                return (
                    <Content key={keyContent} dangerouslySetInnerHTML={{ __html: value }} />
                );
            }

            case TypeContent.media: {
                const Img = styled.img`
                width: 100%;
                object-fit: cover;
            `;

                return (
                    <Content key={keyContent}>
                        <Img src={value} />
                    </Content>
                );
            }

            case TypeContent.button: {
                return (
                    <Content key={keyContent}>
                        {valueParams?.action === Action.moveToPage && <StyleButton onClick={() => handleButtonClick(valueParams)}>{value}</StyleButton>}
                        {valueParams?.action === Action.link && (
                            <StyleButton>
                                <a href={valueParams?.link || ''} target="_blank" rel="noreferrer">
                                    {value}
                                </a>
                            </StyleButton>
                        )}
                        {valueParams?.action && [Action.submit, Action.cancel].includes(valueParams?.action) && <StyleButton>{value}</StyleButton>}
                    </Content>
                );
            }

            case TypeContent.confirm_phone: {
                return (
                    <Content key={keyContent}>
                        <PhoneField {...dataAll} label={value} />
                    </Content>
                );
            }

            case TypeContent.confirm_email: {
                return (
                    <Content key={keyContent}>
                        <EmailField {...dataAll} label={value} />
                    </Content>
                );
            }

            case TypeContent.popupTitle: {
                return (
                    <Content key={keyContent}>
                        <h2 className="popup__title p-0">{value}</h2>
                    </Content>
                );
            }

            case TypeContent.popupSubTitle: {
                return (
                    <Content key={keyContent}>
                        <p className="popup__text p-0">{value}</p>
                    </Content>
                );
            }

            case TypeContent.form: {
                return (
                    <Content key={keyContent}>
                        <FormFields
                            {...dataAll}
                            acceptLabel={value}
                            labels={{
                                firstName: valueParams?.firstName,
                                lastName: valueParams?.lastName,
                                email: valueParams?.email,
                                IBAN: valueParams?.IBAN,
                                bic: valueParams?.bic,
                            }}
                        />
                    </Content>
                );
            }

            case TypeContent.checkbox: {
                return (
                    <Content key={keyContent}>
                        <Checkbox name={valueParams?.type || keyContent} required >
                            <p dangerouslySetInnerHTML={{ __html: value }} />
                        </Checkbox>
                    </Content>
                );
            }

            case TypeContent.formCheck: {
                return (
                    <Content key={keyContent}>
                        <FormCheck label={value} />
                    </Content>
                );
            }

            case TypeContent.products: {
                return (
                    <Content key={keyContent}>
                        <Products products={products} data={dataAll} />
                    </Content>
                );
            }

            default: {
                return <div>what wrong?</div>
            }
        }
    }

    const renderPage = (template: PageTemplateTypes) => {
        const DTO: IPage | undefined = pages.find(page => page.template === template);
        if (!DTO) {
            return null;
        }

        const key = uuidv4();
        const pageInfo = Presenter.getPage(DTO);
        const GeneralPage = getGeneralPage(pageInfo.settings);
        const GeneralBody = getGeneralBody(pageInfo.settings);

        if (template === PageTemplateTypes.popup_confirm) {
            const buttons = pageInfo.data.filter((item: ISubBlock) => item.title === "2 Button for popup");
            const allWithoutButtons = pageInfo.data.filter((item: ISubBlock) => item.title !== "2 Button for popup");

            return (
                <ModalFormWizard
                    isVisible={open}
                    onClose={closePopup}
                    onSubmit={handleSubmitConfirm}
                >
                    {confirm ? (
                        <>
                            <div className="popup__form">
                                <div className="popup__title">Enter code</div>
                                <CodeField errorMessage={errorMessage} />
                            </div>
                            <div className="popup__footer">
                                {buttons.map(renderBlock)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="popup__form">
                                {allWithoutButtons.map(renderBlock)}
                            </div>
                            <div className="popup__footer">
                                {buttons.map(renderBlock)}
                            </div>
                        </>
                    )}
                </ModalFormWizard>
            );
        }

        if (template === PageTemplateTypes.form) {
            return (
                <GeneralBody key={key + 'body'}>
                    <GeneralPage key={key} as={'form'} onSubmit={handleSubmitForm}>
                        {pageInfo.data.map(renderBlock)}
                    </GeneralPage>
                </GeneralBody>
            );
        }

        if (template === PageTemplateTypes.upload) {
            return (
                <GeneralBody key={key + 'body'}>
                    <GeneralPage key={key} as={'form'} onSubmit={handleSubmitUpload}>
                        {pageInfo.data.map(renderBlock)}
                    </GeneralPage>
                </GeneralBody>
            );
        }

        return (
            <GeneralBody key={key + 'body'}>
                <GeneralPage key={key}>
                    {pageInfo.data.map(renderBlock)}
                </GeneralPage>
            </GeneralBody>
        );
    }

    return (
        <div className="page">
            {renderPage(step)}
            {step === PageTemplateTypes.welcome && renderPage(PageTemplateTypes.popup_confirm)}
        </div>
    );
});

export const LandingPage = withRouter(Landing);